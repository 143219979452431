<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-16 21:02:43
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:31
 * @Description: 创建 修改 用户组
 * @FilePath: \src\views\Frame\Auth\Group\NUGroup.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="用户组名称：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="显示排序：" style="width: 45%">
            <el-input-number
              v-model="m.sort"
              size="small"
              :min="1"
              :max="999999"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="用户组状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-rightselect-box">
            <el-form-item label="角色分配：">
              <el-card shadow="never">
                <el-tree
                  ref="role-tree"
                  show-checkbox
                  :data="allRoleList"
                  :props="{ label: 'name' }"
                  node-key="krole_id"
                  highlight-current
                  :default-checked-keys="roleSelectList"
                >
                </el-tree>
              </el-card>
            </el-form-item>
          </div>

          <div class="from-rightselect-box">
            <el-form-item label="权限分配：">
              <el-card shadow="never">
                <el-tree
                  ref="right-tree"
                  show-checkbox
                  :data="allRightList"
                  :props="{ children: 'children', label: 'name' }"
                  node-key="right_id"
                  :render-content="renderContent"
                  @node-expand="handleExpand"
                  highlight-current
                  :default-checked-keys="rightSelectList"
                >
                </el-tree>
              </el-card>
            </el-form-item>
          </div>

          <div class="from-notes-box">
            <el-form-item label="描述：" style="width: 90%; display: block">
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewGroup()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateGroup()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>
<script>
import {
  createNewGroup,
  updateGroup,
  getGroupInfo,
  getRightTable,
  getRoleTable,
} from "../api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        name: "",
        sort: 1,
        status: 1,
        notes: "",
        right_list: "",
        role_list: "",
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      kor_id: null, //组织ID
      allRightList: [], //所有权限列表
      rightSelectList: [], //选中的权限
      allRoleList: [], //所有角色列表
      roleSelectList: [], //选中的角色
    };
  },
  methods: {
    //打开组件
    open(id, kor_id = null) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.kor_id = kor_id; //组织ID
      this._GetAllRightList(); //加载权限列表
      this._GetAllRoleList(); //加载角色列表
      this.$nextTick(() => {
        if (id === 0) {
          //0 新建
          this.title = "新建用户组";
          this.loading = false;
        } else {
          //修改
          this.title = "修改用户组";
          this._GetGroupInfo(id);
        }
      });
    },

    //创建新用户组
    _CreateNewGroup() {
      var m = this.m;
      m.right_list = ""; //重置权限列表
      this.$refs["right-tree"].getCheckedKeys().forEach(function (id) {
        m.right_list += id + ",";
      });

      m.role_list = ""; //重置角色列表
      this.$refs["role-tree"].getCheckedKeys().forEach(function (id) {
        m.role_list += id + ",";
      });
      createNewGroup({
        kor_id: this.kor_id, //组织ID
        name: m.name, //名称
        sort: m.sort, //排序
        status: m.status, //状态
        notes: m.notes, //描述
        right_list: m.right_list, //权限列表
        role_list: m.role_list, //角色列表
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改用户组
    _UpdateGroup() {
      var m = this.m;
      m.right_list = ""; //重置权限列表
      this.$refs["right-tree"].getCheckedKeys().forEach(function (id) {
        m.right_list += id + ",";
      });

      m.role_list = ""; //重置角色列表
      this.$refs["role-tree"].getCheckedKeys().forEach(function (id) {
        m.role_list += id + ",";
      });
      updateGroup({
        kg_id: this.id, //角色ID
        name: m.name, //名称
        sort: m.sort, //排序
        status: m.status, //状态
        notes: m.notes, //描述
        right_list: m.right_list, //权限列表
        role_list: m.role_list, //角色列表
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取指定用户组参数
    _GetGroupInfo(id) {
      getGroupInfo({
        kgroup_id: id,
      })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.name = d.name; //用户组名称
          m.sort = d.sort; //排序
          m.status = d.status; //状态
          m.notes = d.notes; //描述
          this.roleSelectList = d.role_list; //角色列表
          this.rightSelectList = Object.values(d.right_list); //权限列表
          this.loading = false;
        })
        .catch((err) => {
          this.isShow = false;
          this.$utils.error(err.err_msg);
        });
    },

    //获取所有权限列表
    _GetAllRightList() {
      getRightTable({
        pageNo: 1,
        pageSize: 999999,
      })
        .then((res) => {
          this.allRightList = res.data.data; //写入列表
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取所有角色列表
    _GetAllRoleList() {
      getRoleTable({
        kor_id: this.kor_id,
        pageNo: 1,
        pageSize: 999999,
      })
        .then((res) => {
          this.allRoleList = res.data.data; //写入列表
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },

    /************** Tree底层横向排列用的 **************/

    //节点被展开时触发
    handleExpand() {
      //因为该函数执行在renderContent函数之前，所以得加this.$nextTick()
      this.$nextTick(() => {
        this.changeCss();
      });
    },

    //绘制节点内容
    renderContent(h, { node }) {
      //树节点的内容区的渲染 Function
      let classname = "";

      if (node.childNodes.length === 0) {
        classname = "foo";
      }

      return [
        h(
          "span",
          {
            class: classname,
          },
          node.label
        ),
      ];
    },

    //重绘CSS
    changeCss() {
      var levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
      for (var i = 0; i < levelName.length; i++) {
        // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
        levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function () {
          this.style.backgroundColor = "#fff";
        };
      }
    },
  },
};
</script>
